import React, { useContext, useState } from "react";
import APIContext from "../../hooks/API";
import { LoginContext } from "../Login";
import { Saved } from ".";
import { Container, ContainerHeader } from "../Container";
import { T } from "../traks";

const UserForm = () => {
	const { user } = useContext(LoginContext);
	const { put } = useContext(APIContext);
	let details = user?.details;
	const [firstname, setFirstname] = useState(details?.firstname);
	const [lastname, setLastname] = useState(details?.lastname);
	const [homephonenumber, setHomephonenumber] = useState(
		details?.homephonenumber
	);
	const [workphonenumber, setWorkphonenumber] = useState(
		details?.workphonenumber
	);
	const [street, setStreet] = useState(details?.street);
	const [city, setCity] = useState(details?.city);
	const [zip, setZip] = useState(details?.zip);
	const [state, setState] = useState(details?.state);
	const [country, setCountry] = useState(details?.country);
	const [madeChanges, setMadeChanges] = useState(false);
	const [saved, setSaved] = useState(false);

	const setValue = (val, func) => {
		setMadeChanges(true);
		setSaved(false);
		func(val);
	};

	const cancel = () => {
		setMadeChanges(false);
		setFirstname(details.firstname);
		setLastname(details.lastname);
		setHomephonenumber(details.homephonenumber);
		setWorkphonenumber(details.workphonenumber);
		setStreet(details.street);
		setCity(details.city);
		setZip(details.zip);
		setState(details.state);
		setCountry(details.country);
		setSaved(false);
	};

	const save = (e) => {
		e.preventDefault();
		setMadeChanges(false);
		let args = {
			firstname,
			lastname,
			homephonenumber,
			workphonenumber,
			street,
			city,
			zip,
			state,
			country,
		};
		put({
			path: `/user/${user.agentId}`,
			args,
		}).then((e) => {
			user.details = args;
			setSaved(true);
		});
	};

	return (
		<Container>
			<ContainerHeader>User info</ContainerHeader>
			<div className="my-2 mx-3">
				<div className="d-flex flex-column">
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="input-group m-2">
							<span className="input-group-text col-4">
								<T>First and last name</T>
							</span>
							<input
								type="text"
								placeholder="First name"
								className="form-control col-4"
								value={firstname}
								onChange={(e) => setValue(e.target.value, setFirstname)}
							/>
							<input
								type="text"
								placeholder="Last name"
								className="form-control col-4"
								value={lastname}
								onChange={(e) => setValue(e.target.value, setLastname)}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="input-group m-2">
							<span className="input-group-text col-4">
								<T>Home Phone</T>
							</span>
							<input
								placeholder="Home phone"
								type="text"
								className="form-control col-8"
								value={homephonenumber}
								onChange={(e) => setValue(e.target.value, setHomephonenumber)}
							/>
						</div>
						<div className="input-group m-2 ">
							<span className="input-group-text col-4">
								<T>Work Phone</T>
							</span>
							<input
								placeholder="Work phone"
								type="text"
								className="form-control"
								value={workphonenumber}
								onChange={(e) => setValue(e.target.value, setWorkphonenumber)}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="input-group m-2 ">
							<span className="input-group-text col-4">
								<T>Address</T>
							</span>

							<input
								placeholder="Address"
								type="text"
								className="form-control"
								value={street}
								onChange={(e) => setValue(e.target.value, setStreet)}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="input-group m-2">
							<span className="input-group-text col-4">
								<T>Zip and City</T>
							</span>
							<input
								placeholder="Zip"
								type="zip"
								className="form-control"
								value={zip}
								onChange={(e) => setValue(e.target.value, setZip)}
							/>
							<input
								placeholder="City"
								type="text"
								className="form-control"
								value={city}
								onChange={(e) => setValue(e.target.value, setCity)}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="input-group m-2 flex-fill">
							<span className="input-group-text col-4">
								<T>State and country</T>
							</span>
							<input
								placeholder="State"
								type="text"
								className="form-control"
								value={state}
								onChange={(e) => setValue(e.target.value, setState)}
							/>
							<input
								placeholder="Country"
								type="text"
								className="form-control"
								value={country}
								onChange={(e) => setValue(e.target.value, setCountry)}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-md-row justify-content-evenly flex-fill">
						<div className="d-flex flex-row w-100 py-2 justify-content-end">
							<button
								disabled={!madeChanges}
								className="btn btn-light w-25 "
								onClick={cancel}
							>
								<T>Cancel</T>
							</button>
							<button
								disabled={!madeChanges}
								type="submit"
								className="btn btn-warning w-25 ms-4 me-2"
								onClick={save}
							>
								<Saved isSaved={saved} />
							</button>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
};

export default UserForm;

import React from "react";
import { T } from "../traks";

const StatusFilter = ({ orderStatus, setOrderStatus }) => {
	const orderStatuses = [
		"All",
		"Authorized",
		"Cancelled",
		"Captured",
		"ConnectionError",
		"Created",
		"Failed",
		"Refunded",
		"Timeout",
		"PartiallyCaptured",
	];
	return (
		<div className="input-group m-2">
			<label className="input-group-text">
				<T>Order status</T>
			</label>
			<select
				className="form-select"
				onChange={(e) => {
					setOrderStatus(e.target.value);
				}}
				defaultChecked={orderStatus}
			>
				{orderStatuses.map((s) => (
					<option key={s} defaultChecked={s === orderStatus}>
						{s}
					</option>
				))}
			</select>
		</div>
	);
};

export default StatusFilter;

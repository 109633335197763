import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../../hooks/API";
import TemplateSelect from "../TemplateSelect";
import { T } from "../../traks";

const validActions = (order) => ({
	capture: order.orderStatus === "Authorized",
	cancel: order.orderStatus === "Authorized",
	refund: order.orderStatus === "Captured",
	delete: order.orderStatus === "Created",
	showInvoice: order.orderStatus === "Captured",
	sendReceipt: order.orderStatus === "Captured",
	partiallyCapture:
		order.orderStatus === "Authorized" ||
		order.orderStatus === "PartiallyCaptured",
	partiallyRefund:
		order.orderStatus === "Captured" ||
		order.orderStatus === "PartiallyRefunded" ||
		order.orderStatus === "PartiallyCaptured",
});

const showInvoice = (get, order, templateKey, setPdf) =>
	get({
		path: `/order/${order.orderId}/invoice`,
		args: { templateKey },
		headers: { Accept: "application/pdf, application/json" },
	}).then((e) => {
		e.text().then((t) => setPdf(`data:application/pdf;base64,${t}`));
	});

const sendReceipt = (post, order, templateKey) => {
	return post({
		path: `/order/${order.orderId}/emailreceipt`,
		args: { templateKey },
	});
};

const refund = (post, order, setReload) =>
	post({
		path: `/order/${order.orderId}/refund`,
	}).then((e) => {
		order.orderStatus = "Refunded";
		setReload((p) => !p);
	});

const cancel = (post, order, setReload) =>
	post({
		path: `/order/${order.orderId}/cancel`,
	}).then((e) => {
		order.orderStatus = "Cancelled";
		setReload((p) => !p);
	});

const capture = (post, order, setReload) =>
	post({
		path: `/order/${order.orderId}/capture`,
	}).then((e) => {
		order.orderStatus = "Captured";
		setReload((p) => !p);
	});

const deleteOrder = (deleteR, order) =>
	deleteR({
		path: `/order/${order.orderId}`,
	}).then((e) => {
		order = null;
	});

const partiallyCapture = (post, order, amount, setReload) =>
	post({
		path: `/order/${order.orderId}/partialcapture`,
		args: { amount },
	}).then(() => {
		setReload((p) => !p);
	});

const partiallyRefund = (post, order, amount, setReload) =>
	post({
		method: "POST",
		path: `/order/${order.orderId}/partialrefund`,
		args: { amount },
	}).then(() => {
		setReload((p) => !p);
	});

const Actions = ({ order, setReload }) => {
	const { post, get, deleteR, error } = useContext(APIContext);
	const [pdf, setPdf] = useState();
	const [templateKey, setTemplateKey] = useState();
	const actions = validActions(
		order,
		post,
		get,
		deleteR,
		setPdf,
		templateKey,
		setReload
	);
	const [captureAmount, setCaptureAmount] = useState();
	const [refundAmount, setRefundAmount] = useState();
	useEffect(() => {
		setPdf();
		setCaptureAmount(order.authorizedAmount - order.capturedAmount);
		setRefundAmount(order.capturedAmount);
	}, [order.authorizedAmount, order.capturedAmount, order.orderId]);
	let date = new Date(Date.now());
	useEffect(() => {
		if (error) alert(error);
	}, [error]);
	if (
		!(
			actions.capture |
			actions.cancel |
			actions.refund |
			actions.partiallyCapture |
			actions.partiallyRefund |
			actions.showInvoice
		)
	)
		return (
			<span>
				<T>No available actions for this order.</T>
			</span>
		);

	return (
		<div className="d-flex flex-column">
			<div className="w-100 d-flex justify-content-between align-items-center flex-row">
				{actions.capture && (
					<ActionButton
						className="btn btn-warning me-2 text-nowrap"
						onClick={() => capture(post, order, setReload)}
					>
						<T>Capture payment</T>
					</ActionButton>
				)}
				{actions.partiallyCapture && (
					<div className="input-group me-2 ">
						<span className="input-group-text col-4">
							<T>Capture amount</T>
						</span>
						<input
							type="number"
							className="form-control"
							value={captureAmount}
							onChange={(e) => setCaptureAmount(parseInt(e.target.value))}
						/>
						<ActionButton
							className="btn btn-warning text-nowrap"
							onClick={() =>
								partiallyCapture(post, order, captureAmount, setReload)
							}
						>
							<T>Partially capture payment</T>
						</ActionButton>
					</div>
				)}
				{actions.partiallyRefund && (
					<div className="input-group me-2 ">
						<span className="input-group-text col-4">
							<T>Refund amount</T>
						</span>
						<input
							type="number"
							className="form-control"
							value={refundAmount}
							onChange={(e) => setRefundAmount(parseInt(e.target.value))}
						/>
						<ActionButton
							className="btn btn-warning text-nowrap"
							onClick={() =>
								partiallyRefund(post, order, refundAmount, setReload)
							}
						>
							<T>Partially refund payment</T>
						</ActionButton>
					</div>
				)}
				{actions.cancel && (
					<button
						className="btn btn-danger me-2 text-nowrap"
						onClick={() => cancel(post, order, setReload)}
					>
						<T>Cancel order</T>
					</button>
				)}
				{actions.refund && (
					<button
						className="btn btn-danger me-2 text-nowrap"
						onClick={() => refund(post, order, setReload)}
					>
						<T>Refund payment</T>
					</button>
				)}
				{actions.showInvoice && (
					<>
						<TemplateSelect
							showInvoice={() => showInvoice(get, order, templateKey, setPdf)}
							sendInvoice={() => sendReceipt(post, order, templateKey)}
							{...{ templateKey, setTemplateKey }}
						/>
						{pdf && (
							<a
								className="btn btn-primary text-nowrap"
								href={pdf}
								target="_blank"
								rel="noreferrer"
								download={`${date.getFullYear()}-${
									date.getMonth() + 1
								}-${date.getDate()}_invoice-${order.invoiceId}.pdf`}
							>
								<T>Download invoice</T>
							</a>
						)}
					</>
				)}
			</div>
			{pdf && (
				<div className="my-4 ratio" style={{ "--bs-aspect-ratio": "125%" }}>
					<iframe
						title="invoice"
						src={pdf}
						type="application/pdf"
						width="100%"
						height="500px"
					/>
				</div>
			)}
		</div>
	);
};

const ActionButton = ({ onClick, className, children }) => {
	const [loading, setLoading] = useState(false);
	return (
		<button
			className={className}
			onClick={() => {
				setLoading(true);
				onClick().finally((e) => setLoading(false));
			}}
		>
			{loading ? (
				<div className="spinner-border text-light" role="status" />
			) : (
				children
			)}
		</button>
	);
};

export default Actions;

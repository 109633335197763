import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, ContainerHeader } from "../Container";
import { T } from "../traks";

const DownloadAsCSV = ({ data, columns }) => {
	const url = useMemo(
		() =>
			URL.createObjectURL(
				new Blob([dataToCSV(data, columns)], { type: "text/plain" })
			),
		[data, columns]
	);
	return (
		<a className="mx-3" href={url} download="orders.csv">
			Download as CSV
		</a>
	);
};

const dataToCSV = (data, columns) => {
	let rows = data
		.map((d) => columns.map((c) => getField(d, c)).join("\t"))
		.join("\n");
	let header = columns.join("\t") + "\n";
	return header + rows;
};

const Table = ({ data, sortBy, setSortBy, reverseSort, setReverseSort }) => {
	const navigate = useNavigate();
	const [columns, setColumns] = useState([
		"createdTs",
		"invoiceId",
		"orderStatus",
		"details.lines[0].description",
		"details.lines[0].text",
		"details.shippingaddress.email",
		"authorizedAmount",
		"capturedAmount",
		"refundedAmount",
	]);

	const totals = useMemo(
		() =>
			data.reduce(
				(acc, curr) => {
					Object.keys(acc).forEach((k) => {
						let addend = Number(getField(curr, k));
						if (Number(acc[k] + addend) > 0) {
							acc[k] += addend;
						}
					});
					return acc;
				},
				columns.reduce((cols, col) => {
					let field = getField(data[0], col);
					if (
						col.endsWith("Ts") ||
						isNaN(Number(field)) ||
						String(field).length < 1
					) {
						return { ...cols, [col]: "" };
					}
					return { ...cols, [col]: 0 };
				}, {})
			),
		[data, columns]
	);

	const handleColumnsSort = useCallback(
		(column) => {
			if (column === sortBy) {
				setReverseSort((p) => !p);
			} else {
				setReverseSort(false);
			}
			setSortBy(column);
		},
		[sortBy]
	);
	if (!data?.length)
		return (
			<Container nobreak>
				<ContainerHeader>
					<T>Orders</T>
				</ContainerHeader>
				<span className="m-4">
					<T>Nothing to show. Press search to fetch orders.</T>
				</span>
			</Container>
		);

	return (
		<div className="rounded shadow d-flex flex-column justify-content-center m-4 rounded border">
			<ContainerHeader>
				<T>Orders</T>
			</ContainerHeader>
			<DownloadAsCSV data={data} columns={columns} />
			<ChangeColumns setColumns={setColumns} />
			<div className="d-flex my-2 mx-3">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>
								<T>OrderId</T>
							</th>
							{columns.map((k) => (
								<th key={k}>
									<div className="m-1 d-flex flex-row gap-2">
										<div>{k}</div>
										<div
											className="border-light rounded cursor_pointer"
											onClick={() => handleColumnsSort(k)}
										>
											<i
												className={`${
													sortBy === k && !reverseSort ? "text-primary" : ""
												} bi-arrow-down`}
											/>
											<i
												className={`${
													sortBy === k && reverseSort ? "text-primary " : ""
												} bi bi-arrow-up`}
											/>
										</div>
									</div>
								</th>
							))}
						</tr>
					</thead>
					<Rows
						key={`sortBy${sortBy}-direction${reverseSort}`}
						data={data}
						columnNames={columns}
						navigateToOrder={(order) =>
							navigate(`/orders/${order.companyId}/${order.orderId}`, {})
						}
						totals={totals}
					/>
					<tfoot className="fw-bold">
						<tr>
							{columns.map((k) => (
								<Cell key={k} colName={k} colValue={totals[k]} />
							))}
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};

const ChangeColumns = ({ setColumns }) => {
	const [column, setColumn] = useState("");

	return (
		<div className="d-flex flex-column flex-xl-row justify-content-evenly flex-fill">
			<div className="input-group m-2 p-2">
				<span className="input-group-text">
					<T>Add or remove column</T>
				</span>
				<input
					type="text"
					placeholder="Column name"
					className="form-control"
					value={column}
					onChange={(e) => setColumn(e.target.value)}
				/>
				<div className="input-group-append">
					<button
						className="btn btn-outline-secondary"
						onClick={() => {
							setColumns((p) => [...p, column]);
							setColumn("");
						}}
					>
						<T>Add column</T>
					</button>
					<button
						className="btn btn-outline-secondary"
						onClick={() => {
							setColumns((p) => p.filter((p) => p !== column));
							setColumn("");
						}}
					>
						<T>Remove column</T>
					</button>
				</div>
			</div>
		</div>
	);
};

const Rows = ({ data, columnNames, navigateToOrder, totals }) => (
	<tbody>
		{data.map((item) => (
			<tr key={item.orderId}>
				<LinkCell
					onClick={() => navigateToOrder(item)}
					orderId={item.orderId}
				/>
				{columnNames.map((k) => (
					<Cell key={k} colName={k} colValue={getField(item, k)} />
				))}
			</tr>
		))}
	</tbody>
);

export const getField = (obj, key) =>
	key.split(".").reduce((a, c) => indexedOrValue(a, c), obj);

const indexedOrValue = (obj, nest) => {
	let r = nest.match(/\[([0-9]+)\]/);
	if (!r) {
		if (!obj) return obj;
		return obj[nest];
	}
	let key = nest.slice(0, r.index);
	let idx = parseInt(r[1]);
	if (isNaN(idx)) return obj;
	if (!obj || !obj[key]) return undefined;
	return obj[key][idx];
};

const Cell = ({ colName, colValue, style = {} }) => {
	let val = colName.includes("Ts")
		? colValue.slice(0, colValue.indexOf(".")).replace("T", " ")
		: colValue;
	return (
		<td
			style={{
				whiteSpace: "nowrap",
				overflow: "hidden",
				textOverflow: "ellipsis",
				maxWidth: "200px",
				...style,
			}}
		>
			{val}
		</td>
	);
};

const LinkCell = ({ onClick, orderId }) => (
	<td
		style={{
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis",
			maxWidth: "200px",
		}}
		className="cursor_pointer text-primary"
		onClick={onClick}
	>
		{orderId}
	</td>
);

export default Table;

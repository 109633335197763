import React, { useContext } from "react";
import APIContext from "../../hooks/API";
import { T } from "../traks";

const CompanySelect = ({ affiliates }) => {
	const { companyId, setCompanyId } = useContext(APIContext);
	if (!affiliates.length) {
		return (
			<div className="text-danger m-2">
				<T>You are currently not affiliated with any company!</T>
			</div>
		);
	}
	return (
		<div className="input-group m-2">
			<label className="input-group-text">
				<T>Company</T>
			</label>
			<select
				className="form-select"
				defaultValue={companyId}
				onChange={(e) => {
					setCompanyId(e.target.value);
				}}
			>
				{affiliates.map((s) => (
					<option key={s} defaultChecked={s === companyId}>
						{s}
					</option>
				))}
			</select>
		</div>
	);
};

export default CompanySelect;

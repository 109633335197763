import React from "react";
import { T } from "../traks";

const DateSelect = ({ fromDate, setFromDate, toDate, setToDate }) => {
	return (
		<div className="d-flex flex-column flex-xl-row flex-fill justify-content-evenly">
			<div className="input-group m-2">
				<span className="input-group-text">
					<T>From</T>
				</span>
				<input
					className="form-control"
					type="date"
					onChange={(e) => setFromDate(e.target.value)}
					defaultValue={fromDate}
				/>
			</div>
			<div className="input-group m-2">
				<span className="input-group-text">
					<T>To</T>
				</span>
				<input
					className="form-control"
					type="date"
					onChange={(e) => setToDate(e.target.value)}
					defaultValue={toDate}
				/>
			</div>
		</div>
	);
};

export default DateSelect;

import React, { useContext, useEffect, useState } from "react";
import { LoginContext, MFA, PasswordValidation } from "../Login";
import APIContext from "../../hooks/API";
import { Saved } from ".";
import QRCode from "react-qr-code";
import { Container, ContainerHeader } from "../Container";
import { T } from "../traks";

const SecuritySettings = () => {
	const { user } = useContext(LoginContext);
	const { post } = useContext(APIContext);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [saved, setSaved] = useState(false);

	const IsValid = (pw) => {
		var passwordRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
		var hasSpecialChar = /[',.<>?/:;{}@#%&*()_+\-=§±~!]+/;
		if (passwordRegExp.test(pw) && hasSpecialChar.test(pw)) {
			return true;
		} else {
			return false;
		}
	};
	const changePassword = () => {
		post({
			path: `/user/${user.agentId}`,
			args: { action: "updatePassword", arguments: { password, newPassword } },
		}).then((e) => {
			if (e.ok) {
				clearAll();
				setSaved(true);
			}
		});
	};

	const clearAll = () => {
		setPassword("");
		setNewPassword("");
		setConfirmPassword("");
	};
	if (!user) return;

	return (
		<Container>
			<ContainerHeader>
				<T>Security</T>
			</ContainerHeader>
			<div className="my-2 mx-3">
				<div className="d-flex flex-column">
					<h4 className="m-2">Password</h4>
					<div className="m-2">
						<T>
							Passwords must consist of 8 characters and contain at least one
							lowercase, uppercase, numeric and special character.
						</T>
					</div>
					<div className="input-group m-2">
						<span className="input-group-text col-4">
							<T>Current password</T>
						</span>
						<input
							type="password"
							placeholder="Current password"
							className="form-control"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</div>
					<div className="input-group m-2">
						<span className="input-group-text col-4">
							<T>New password</T>
						</span>
						<input
							placeholder="New password"
							type="password"
							className={`form-control ${
								!newPassword.length
									? ""
									: IsValid(newPassword)
									? "is-valid"
									: "is-invalid"
							}`}
							value={newPassword}
							onChange={(e) => {
								setNewPassword(e.target.value);
							}}
						/>
						<div className="invalid-feedback">
							<T>Invalid password!</T>
						</div>
					</div>
					<div className="input-group m-2">
						<span className="input-group-text col-4">
							<T>Confirm password</T>
						</span>
						<input
							placeholder="Confirm new password"
							type="password"
							className={`form-control ${
								!confirmPassword.length
									? ""
									: newPassword === confirmPassword
									? "is-valid"
									: "is-invalid"
							}`}
							value={confirmPassword}
							onChange={(e) => setConfirmPassword(e.target.value)}
						/>
						<div className="invalid-feedback">
							<T>Passwords must match!</T>
						</div>
					</div>
					<div className="d-flex flex-row w-100 p-2 justify-content-end">
						<button
							disabled={
								password &&
								newPassword.length &&
								newPassword === confirmPassword
							}
							className="btn btn-light w-25 "
							onClick={clearAll}
						>
							<T>Cancel</T>
						</button>
						<button
							disabled={
								!(
									password &&
									newPassword.length &&
									newPassword === confirmPassword
								)
							}
							type="submit"
							className="btn btn-warning w-25 ms-4"
							onClick={changePassword}
						>
							<Saved isSaved={saved} />
						</button>
					</div>
				</div>
			</div>
			<Enable2FA />
		</Container>
	);
};

const Enable2FA = () => {
	const [password, setPassword] = useState("");
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const [useMFA, setUseMFA] = useState(null);
	const [change, setChange] = useState(false);
	const [setupURL, setSetupURL] = useState("");
	const { post } = useContext(APIContext);
	const { user } = useContext(LoginContext);

	useEffect(() => {
		if (passwordIsValid)
			post({
				path: `/login`,
				args: { password, MFA, agentId: user.agentId, dryrun: true },
			}).then((e) => {
				console.log({ passwordIsValid: e });
				setUseMFA(e.useMFA);
			});
	}, [password, passwordIsValid, post, user.agentId]);

	const handleSwitch = (e) => {
		setUseMFA(e.target.checked);
		if (e.target.checked) {
			post({
				path: `/user/${user.agentId}`,
				args: { action: "setupMFA" },
			}).then((e) => {
				setSetupURL(e);
			});
		} else {
			setSetupURL("");
			post({
				path: `/user/${user.agentId}`,
				args: { action: "disableMFA" },
			}).then((e) => {
				console.log("disable MFA:", e);
			});
		}
	};

	const verifyMFA = (MFA) => {
		post({
			path: `/login`,
			args: { password, MFA, agentId: user.agentId, dryrun: true },
		}).then((e) => {
			if (e.validMFA) {
				setSetupURL();
			} else {
				post({
					path: `/user/${user.agentId}`,
					args: { action: "disableMFA" },
				}).then((e) => {
					console.log("disable MFA:", e);
				});
				setUseMFA(false);
			}
		});
	};

	return (
		<div className="my-2 mx-3">
			<h4 className="m-2">
				<T>Two-Factor Authentication</T>
			</h4>
			<div className="m-2">
				<T>Use authenticator app to verify login.</T>
			</div>
			<button className="btn btn-info m-2" onClick={() => setChange((p) => !p)}>
				<T>Open settings</T>
			</button>
			{change && (
				<div className="m-2">
					<span className="text-danger">
						<T>Please enter your password to continue</T>
					</span>
					<PasswordValidation
						{...{
							agentId: user.agentId,
							password,
							setPassword,
							passwordIsValid,
							setPasswordIsValid,
						}}
					/>
					{passwordIsValid && (
						<>
							<div className="form-check form-switch">
								<input
									checked={!!useMFA}
									className="form-check-input"
									type="checkbox"
									id="flexSwitchCheckDefault"
									onChange={handleSwitch}
								/>
								<label
									className="form-check-label"
									htmlFor="flexSwitchCheckDefault"
								>
									<T>2FA enabled</T>
								</label>
							</div>
							{setupURL ? (
								<div className="d-flex flex-column align-items-center">
									<h3>
										<T>Scan the QR-code</T>
									</h3>
									<QRCode
										value={setupURL}
										title="Scan this with your code app"
									/>
									<MFA
										onSubmit={verifyMFA}
										title="Enter the verification code to enable 2FA"
									/>
								</div>
							) : null}
						</>
					)}
				</div>
			)}
		</div>
	);
};

export default SecuritySettings;

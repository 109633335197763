import MainPage from "./components/MainPage";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Login, { LoginContext, useLogin } from "./components/Login";
import NoMatch from "./components/NoMatch";
import BamboraCheckout from "./components/BamboraCheckout";
import NavBar from "./components/NavBar";
import Orders from "./components/Orders/Orders";
import Authorized from "./components/Authorized";
import { useContext, useEffect } from "react";
import APIContext from "./hooks/API";
import ShowOrder from "./components/Orders/ShowOrder";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

function App() {
	const loginCtx = useLogin();
	return (
		<LoginContext.Provider value={loginCtx}>
			<BrowserRouter>
				{!!loginCtx.user && <NavBar />}
				<Routes>
					<Route exact path="/" element={<Landing />} />
					<Route
						path="/home"
						element={
							<Authorized>
								<MainPage />
							</Authorized>
						}
					/>
					<Route path="/login" element={<Login />} />
					<Route
						path="/orders"
						element={
							<Authorized>
								<Orders />
							</Authorized>
						}
					/>
					<Route
						path="/orders/:companyId/:orderId"
						element={
							<Authorized>
								<ShowOrder />
							</Authorized>
						}
					/>
					<Route path="/bamboraCheckout" element={<BamboraCheckout />} />
					<Route
						path="/docs"
						element={
							<Authorized>
								<SwaggerDocs />
							</Authorized>
						}
					/>
					<Route path="*" element={<NoMatch />} />
				</Routes>
			</BrowserRouter>
		</LoginContext.Provider>
	);
}

const Landing = () => {
	const { authToken } = useContext(APIContext);
	const navigate = useNavigate();
	useEffect(() => {
		if (authToken) navigate("/home");
	}, [authToken, navigate]);
	console.log("Landing");
	return <Login />;
};

const SwaggerDocs = () => {
	return <SwaggerUI url="api.yaml" />;
};

export default App;

import React, { useContext } from "react";
import { LoginContext } from "./Login";
import UserForm from "./Forms/UserForm";
import SecuritySettings from "./Forms/SecuritySettings";
import { Container, ContainerHeader } from "./Container";
import { T } from "./traks";

const MainPage = () => {
	const { signOut, user } = useContext(LoginContext);
	console.log(user);
	return (
		<>
			<h2 className="mx-4">
				<T>Welcome {user?.agentId}</T>
			</h2>
			{!!user?.affiliates?.length && (
				<Container>
					<ContainerHeader>
						<T>Affiliates</T>
					</ContainerHeader>
					<div className="d-flex flex-col m-2 mb-4">
						{user.affiliates.map((aff) => (
							<div key={aff} className="mx-3 border rounded p-2">
								{aff}
							</div>
						))}
					</div>
				</Container>
			)}
			{user?.details && <UserForm />}
			<SecuritySettings />
			<button className="btn btn-danger m-4" onClick={signOut}>
				<T>Log out</T>
			</button>
		</>
	);
};

export default MainPage;

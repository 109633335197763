import { useState, useEffect } from "react";

// This hook handles the websocket connection with Kunpa
// See example usage in components/BamboraCheckout.js
const useKunpa = () => {
  const [order, setOrder] = useState();
  const [state, setState] = useState("AWAITING_ORDER");
  const [token, setToken] = useState();
  const [socket, setSocket] = useState();
  useEffect(() => {
    switch (state) {
      case "INITIALIZE_CONNECTION":
        setSocket(
          new WebSocket(
            "wss://kunpauser:secret@9hdlz03qmb.execute-api.eu-north-1.amazonaws.com/test"
          )
        );
        setState("REQUEST_TOKEN");
        break;
      case "REQUEST_TOKEN":
        socket.onopen = () => {
          socket.send(
            JSON.stringify({
              action: "authorize",
              data: order,
            })
          );
          socket.onmessage = (m) => {
            let data = m?.data && JSON.parse(m.data);
            if (data?.url && data?.token) {
              let { token: checkout_token } = data;
              setToken(checkout_token);
              setState("TOKEN_RECEIVED");
            } else if (data) {
              setState("TRANSACTION_COMPLETED");
            } else {
              setState("ERROR");
            }
          };
        };
        break;
      case "ERROR":
        console.log("Something went wrong");
        break;
      default:
        console.log(state);
    }
  }, [state]);

  useEffect(() => {
    if (order) setState("INITIALIZE_CONNECTION");
  }, [order]);

  return { token, state, setOrder };
};

export default useKunpa;

import React, { useContext, useEffect, useRef, useState } from "react";
import APIContext from "../hooks/API";
import { useNavigate } from "react-router-dom";
import { T } from "./traks";

export const LoginContext = React.createContext();

export const useLogin = () => {
	const [agentId, setAgentId] = useState("");
	const [password, setPassword] = useState("");
	const [doLogin, setDoLogin] = useState(false);
	const [MFA, setMFA] = useState("");
	const [user, setUser] = useState();
	const [useMFA, setUseMFA] = useState();
	const { post, setAuthToken, authToken, get } = useContext(APIContext);
	useEffect(() => {
		if (doLogin) {
			post({
				path: "/login",
				args: { agentId, password, MFA, dryrun: false },
			})
				.then((e) => {
					setUseMFA(e?.useMFA);
					setAuthToken(e?.authToken);
				})
				.catch((e) => {
					console.log("login error:", e);
					setAuthToken();
				})
				.finally(() => setDoLogin(false));
		}
	}, [doLogin, agentId, password, MFA, post, setAuthToken, useMFA, setUseMFA]);

	useEffect(() => {
		if (authToken && agentId && !user) {
			get({ path: `/user/${agentId}` }).then((r) => setUser(r));
		} else if (authToken && !agentId && !user) {
			console.log("Got no agentId or user but has authToken");
			get({ path: "/user" }).then((r) => {
				if (!r) {
					// Authtoken is not valid
					setAuthToken();
				} else {
					setAgentId(r.agentId);
				}
			});
		}
	}, [authToken, get, setAgentId, user, agentId, setAuthToken]);

	return {
		agentId,
		setAgentId,
		user,
		password,
		setPassword,
		signIn: () => setDoLogin(true),
		signOut: () => {
			setAuthToken();
			setUser();
		},
		MFA,
		setMFA,
		useMFA,
	};
};

const Login = () => {
	const {
		setAgentId,
		setPassword,
		agentId,
		password,
		signIn,
		setMFA,
		user,
		useMFA,
	} = useContext(LoginContext);
	const [passwordIsValid, setPasswordIsValid] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		if (!!user) {
			navigate("/home");
		}
	}, [navigate, user]);

	return (
		<div className="d-flex justify-content-center p-4 m-4">
			<div className="shadow d-flex flex-column justify-content-center align-items-center">
				<h2 className="m-2">
					<T>Log in</T>
				</h2>
				<div className="d-flex justify-content-center flex-column px-2">
					<div className="input-group mb-2">
						<span className="input-group-text col-4">
							<T>User</T>
						</span>
						<input
							placeholder="user ID"
							type="login"
							className="form-control"
							value={agentId}
							onChange={(e) => setAgentId(e.target.value)}
						/>
					</div>
					<PasswordValidation
						{...{
							agentId,
							password,
							setPassword,
							passwordIsValid,
							setPasswordIsValid,
							signIn,
						}}
					/>

					<input
						className="btn btn-dark rounded px-4 py-2 my-2"
						type="submit"
						value="Sign in"
						onClick={signIn}
						disabled={!passwordIsValid}
					/>
				</div>
				{useMFA && (
					<MFA
						onSubmit={(e) => {
							setMFA(e);
							signIn();
						}}
						title="Enter verification code"
					/>
				)}
			</div>
		</div>
	);
};

export const MFA = ({ onSubmit, title }) => {
	const [value, setValue] = useState([null, null, null, null, null, null]);

	const d0 = useRef();
	const d1 = useRef();
	const d2 = useRef();
	const d3 = useRef();
	const d4 = useRef();
	const d5 = useRef();

	const handleInput = (e, idx) => {
		let n = e.target.value;
		let temp = [...value];
		temp[idx] = n;
		setValue(temp);
		switch (idx) {
			case 0:
				d1.current.focus();
				break;
			case 1:
				d2.current.focus();
				break;
			case 2:
				d3.current.focus();
				break;
			case 3:
				d4.current.focus();
				break;
			case 4:
				d5.current.focus();
				break;
			case 5:
				onSubmit(temp.join(""));
				break;
			default:
				console.log("uncaught case in handleInput:", idx);
		}
	};
	return (
		<div className="p-2 d-flex flex-column justify-content-center align-items-center">
			<h4 className="m-2">{title}</h4>
			<form>
				<div className="row m-2 " style={{ maxWidth: 400 }}>
					<div className="col-lg-2 col-md-2 col-2 ps-0 ps-md-2">
						<input
							ref={d0}
							onChange={(e) => handleInput(e, 0)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
					<div className="col-lg-2 col-md-2 col-2 ps-0 ps-md-2">
						<input
							ref={d1}
							onChange={(e) => handleInput(e, 1)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
					<div className="col-lg-2 col-md-2 col-2 ps-0 ps-md-2">
						<input
							ref={d2}
							onChange={(e) => handleInput(e, 2)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
					<div className="col-lg-2 col-md-2 col-2 pe-0 pe-md-2">
						<input
							ref={d3}
							onChange={(e) => handleInput(e, 3)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
					<div className="col-lg-2 col-md-2 col-2 pe-0 pe-md-2">
						<input
							ref={d4}
							onChange={(e) => handleInput(e, 4)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
					<div className="col-lg-2 col-md-2 col-2 pe-0 pe-md-2">
						<input
							ref={d5}
							onChange={(e) => handleInput(e, 5)}
							type="text"
							className="form-control text-lg text-center"
							aria-label="2fa"
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export const PasswordValidation = ({
	agentId,
	password,
	setPassword,
	passwordIsValid,
	setPasswordIsValid,
	signIn,
}) => {
	const { post } = useContext(APIContext);

	useEffect(() => {
		if (password.length) {
			post({
				path: `/login`,
				args: { agentId, password, MFA: "", dryrun: true },
			}).then((e) => {
				if (e?.password) {
					setPasswordIsValid(true);
				} else {
					setPasswordIsValid(false);
				}
			});
		}
	}, [password, setPasswordIsValid, post, agentId]);

	return (
		<div className="input-group mb-2">
			<span className="input-group-text col-4">Password</span>
			<input
				type="password"
				placeholder="Password"
				className={`form-control ${
					!password.length ? "" : passwordIsValid ? "is-valid" : "is-invalid"
				}`}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				onKeyDown={(e) =>
					passwordIsValid && e.key === "Enter" ? signIn() : null
				}
			/>
			<div className="invalid-feedback">Invalid password!</div>
			<div className="valid-feedback">Password is valid!</div>
		</div>
	);
};

export default Login;

import React, { useState, useEffect, useRef } from "react";
import { InlineCheckout, Event } from "@bambora/checkout-sdk-web";
import useKunpa from "../hooks/useKunpa";

const BamboraCheckout = () => {
	let order = { amount: 777, currency: "DKK", instantcapture: true };
	const { token, setOrder, state } = useKunpa();
	const [history, setHistory] = useState([]);
	const inlineRef = useRef();

	useEffect(() => {
		if (state) setHistory((p) => [...p, state]);
	}, [state]);

	useEffect(() => {
		if (token) {
			const checkout = new InlineCheckout(token, {
				container: inlineRef.current,
			});
			checkout.on(Event.Authorize, (payload) => {
				console.log(
					`Transaction (${payload.data.txnid}) authorized by payment provider`
				);
				checkout.destroy();
			});
			checkout.on(Event.Cancel, (payload) => {
				console.log(`Transaction (${payload.data.txnid}) cancelled`);
				checkout.destroy();
			});
			checkout.on(Event.Close, (payload) => {
				console.log(`Transaction (${payload.data.txnid}) closed`);
				checkout.destroy();
			});
		}
	}, [token]);

	return (
		<>
			<h1>Testing kunpa</h1>
			<button
				onClick={() => {
					setOrder(order);
					setHistory(["AWAITING_ORDER"]);
				}}
			>
				Place Order
			</button>
			<ul>
				{history.map((p) => (
					<li key={p}>{p}</li>
				))}
			</ul>
			<div ref={inlineRef} style={{ width: 600, height: 800 }} />
		</>
	);
};

export default BamboraCheckout;

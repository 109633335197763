import React from "react";

const OrderEvents = ({ events }) => (
	<div style={{ width: "50vw" }}>
		{events?.map((e) => (
			<EventBox key={e.ts} ts={e.ts} event={e.event} />
		))}
	</div>
);

const EventBox = ({ ts, event }) => (
	<div className="my-2 bg-light rounded">
		<EventTs ts={ts} />
		<EventText event={event} />
	</div>
);

const EventTs = ({ ts }) => {
	let [date, time] = ts.split("T");
	return (
		<div className="bg-info rounded-top px-2">
			{date} {time.split(".")[0]}
		</div>
	);
};

const EventText = ({ event }) => {
	let events = event
		.split(/\n/g)
		.slice(0, -1)
		.map((e) => e.replace(/\\"/g, ""));

	if (events.length === 3) {
		console.log(events[2], events[2].split(": ", 2));
	}

	return (
		<div className="d-flex flex-column px-1 py-2">
			{events.map((e, i) => (
				<div
					className="px-1 d-flex flex-row justify-content-between gap-1"
					key={i}
				>
					{e.split(": ", 2).map((v, i) =>
						i === 0 ? (
							<div style={{ flex: 1 }}>{v}</div>
						) : (
							v.split(/->/).map((val, idx) => (
								<div
									className={
										idx === 0
											? "bg-danger bg-opacity-50 mx-1 p-1"
											: "bg-success bg-opacity-50 mx-1 p-1"
									}
									style={{ flex: 1 }}
								>
									{val.replace(/"/g, "")}
								</div>
							))
						)
					)}
				</div>
			))}
		</div>
	);
};

export default OrderEvents;

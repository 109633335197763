import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../hooks/API";
import { useParams } from "react-router-dom";
import Actions from "./Actions/actions";
import {
	Container,
	ContainerBody,
	ContainerHeader,
	ContainerWithSwitchHeader,
} from "../Container";
import { T } from "../traks";
import OrderEvents from "./OrderEvents";

const ShowOrder = () => {
	const [order, setOrder] = useState();
	const { companyId, orderId } = useParams();
	const { get, setCompanyId } = useContext(APIContext);
	const [reload, setReload] = useState(false);
	useEffect(() => {
		if (orderId && companyId) {
			setCompanyId(companyId);
			get({ path: `/order/${orderId}` }).then((e) => {
				if (e) setOrder(e);
			});
		}
	}, [companyId, get, orderId, setCompanyId, setOrder, reload]);
	if (!order) return null;
	const formatter = new Intl.NumberFormat(undefined, {
		style: "currency",
		currency: order.details.currency,
	});
	const shippingaddress = order.details.shippingaddress;
	const billingaddress = order.details.billingaddress;
	return (
		<div className="container-fluid d-flex flex-wrap align-items-start">
			<Container nobreak>
				<ContainerHeader>
					<T>
						Order: {order.orderId} <i>({order.orderStatus})</i>
					</T>
				</ContainerHeader>
				<ContainerBody>
					<Actions order={order} setReload={setReload} />
				</ContainerBody>
			</Container>

			<Timeline order={order} />
			<Address address={shippingaddress} title={"Shipping Address"} />
			<Address address={billingaddress} title={"Billing Address"} />

			<Container nobreak>
				<ContainerHeader>
					<T>Order Total</T>
				</ContainerHeader>
				<ContainerBody>
					<p>
						<span>
							<T>Total: {formatter.format(order.details.amount / 100)}</T>
							<br />
						</span>
						<span>
							<T>
								Vat amount: {formatter.format(order.details.vatamount / 100)}
							</T>
							<br />
						</span>
					</p>
				</ContainerBody>
			</Container>
			<Container nobreak>
				<ContainerHeader>
					<T>Items</T>
				</ContainerHeader>
				<ContainerBody>
					<div className="d-flex flex-column table">
						{order.details.lines.map((item, idx) => (
							<Item key={item.id} item={item} formatter={formatter} />
						))}
					</div>
				</ContainerBody>
			</Container>
			<Container nobreak>
				<ContainerHeader>
					<T>Events</T>
				</ContainerHeader>
				<ContainerBody>
					<OrderEvents events={order.events} />
				</ContainerBody>
			</Container>
			<ContainerWithSwitchHeader
				initialShow={false}
				headerText={<T>Raw data</T>}
			>
				<ContainerBody>
					<RawData order={order} />
				</ContainerBody>
			</ContainerWithSwitchHeader>
		</div>
	);
};

const Timeline = ({ order }) => {
	let TsKeys = Object.keys(order).filter((k) => k.includes("Ts") && order[k]);
	const dates = TsKeys.reduce(
		(p, c) => ({ ...p, [c]: new Date(order[c]) }),
		{}
	);

	TsKeys = TsKeys.sort((a, b) => dates[a] >= dates[b]);
	let intervals = TsKeys.map((d, i) => [
		d.slice(0, -2),
		!i || dates[d].getTime() - dates.createdTs.getTime(),
	]);

	return (
		<Container nobreak>
			<ContainerHeader>
				<T>Timeline</T>
			</ContainerHeader>
			<ContainerBody>
				<p>
					<span>
						<T>Created: {dates.createdTs.toLocaleString()}</T>
						<br />
					</span>
					{intervals.map(
						(v, i) =>
							i > 0 && (
								<span key={`interval-${i}`}>
									{v[0]} {intervalStr(v[1])}
									<br />
								</span>
							)
					)}
				</p>
			</ContainerBody>
		</Container>
	);
};

const intervalStr = (interval) => {
	let d = Math.floor(interval / (1000 * 60 * 60 * 24));
	let h = Math.floor((interval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
	let m = Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60));
	let s = Math.floor((interval % (1000 * 60)) / 1000);
	const toStr = (val, unit) => (val || unit === "s" ? `${val}${unit} ` : "");
	return `+${toStr(d, "d")}${toStr(h, "h")}${toStr(m, "m")}${toStr(s, "s")}`;
};

const Item = ({ item, formatter }) => {
	return (
		<div className="py-3">
			<ConditionalSpan value={item.id} prefix={"Id: "} />
			<ConditionalSpan value={item.linenumber} prefix={"Line number: "} />
			<br />
			<span className="fst-italic fs-5">
				<ConditionalSpan value={item.description} />
			</span>
			<span className="fs-6">
				<ConditionalSpan value={item.text} />
			</span>
			<br />
			<ConditionalSpan
				value={item.quantity}
				prefix={<T>Units: </T>}
				postfix={<span> {item.unit}</span>}
			/>
			<ConditionalSpan
				value={formatter.format(item.unitprice / 100)}
				prefix={<T>Unit net: </T>}
			/>
			<ConditionalSpan
				value={formatter.format(item.unitpriceinclvat / 100)}
				prefix={<T>Unit gross: </T>}
			/>
			<ConditionalSpan
				value={item.vatrate}
				prefix={`${item.vattype}: `}
				postfix={"%"}
			/>
			<ConditionalSpan value={item.producturl} prefix={<T>Url: </T>} />
		</div>
	);
};

const Address = ({ address, title }) => {
	let hasValues = Object.values(address).reduce((p, c) => p + c, "");
	if (!hasValues) return null;
	return (
		<Container nobreak>
			<ContainerHeader>{title}</ContainerHeader>
			<ContainerBody>
				<span>
					<ConditionalSpan value={address.att} prefix={<T>Att.: </T>} />
					<ConditionalSpan
						value={
							(address.firstname || address.lastname) &&
							`${address.firstname} ${address.lastname}`
						}
					/>
					<ConditionalSpan value={address.street} />
					<ConditionalSpan
						value={
							(address.zip || address.city) && `${address.zip} ${address.city}`
						}
					/>
					<ConditionalSpan value={address.state} />
					<ConditionalSpan value={address.country} />
					<br />
					<ConditionalSpan
						value={address.homephonenumber}
						prefix={
							<span>
								<T>Home: </T>
							</span>
						}
					/>
					<ConditionalSpan
						value={address.workphonenumber}
						prefix={
							<span>
								<T>Work: </T>
							</span>
						}
					/>
					<ConditionalSpan value={address.email} />
				</span>
			</ContainerBody>
		</Container>
	);
};

const ConditionalSpan = ({ value, prefix, postfix }) =>
	!!value && (
		<>
			{prefix}
			{value}
			{postfix}
			<br />
		</>
	);

const RawData = ({ order }) => (
	<span>
		<pre>{JSON.stringify(order, undefined, 2)}</pre>
	</span>
);

export default ShowOrder;

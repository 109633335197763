import { T } from "../traks";

export const Saved = ({ isSaved }) => (
	<span>
		{isSaved ? (
			<span>
				<T>Saved</T>
				<span className="bi bi-check"></span>
			</span>
		) : (
			<T>Save</T>
		)}
	</span>
);

import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { T } from "./traks";

export const NavigationContext = React.createContext();
const NavBar = () => {
	const routes = useMemo(
		() => [
			{ route: "/home", txt: <T>Home</T> },
			// { route: "/settings", txt: <T>Settings</T> },
			{ route: "/orders", txt: <T>Orders</T> },
			{ route: "/docs", txt: <T>Documentation</T> },
		],
		[]
	);
	const [route, setRoute] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		if (route === "") {
			const r = window.location.href.split("/").slice(-1)[0];
			setRoute(r);
		}
	}, [route]);

	return (
		<nav className="navbar navbar-expand navbar-light pb-0 bg-light">
			{routes &&
				routes.map((r) => (
					<NavBarItem
						key={r.route}
						selected={route?.includes(r.route)}
						route={r.route}
						setRoute={() => {
							navigate(r.route);
							setRoute(r.route);
						}}
					>
						{r.txt}
					</NavBarItem>
				))}
		</nav>
	);
};

const NavBarItem = ({ selected, setRoute, children }) => (
	<span
		className={`nav-link ${
			selected ? "active bg-white rounded-top cursor_pointer" : "cursor_pointer"
		}`}
		onClick={setRoute}
	>
		{children}
	</span>
);

export default NavBar;

import { useState } from "react";

export const Container = ({ children, nobreak }) => {
	return (
		<div
			className={`rounded shadow d-flex flex-column justify-content-center m-4 rounded border${
				nobreak ? "" : " col-xl-6"
			}`}
		>
			{children}
		</div>
	);
};

export const ContainerHeader = ({ children }) => (
	<h3 className="bg-light mt-0 py-2 px-4 rounded-top d-flex flex-row justify-content-between gap-2 align-items-center">
		{children}
	</h3>
);

export const ContainerBody = ({ children }) => (
	<div className="d-flex flex-fill mx-4 my-2">{children}</div>
);

const Switch = ({ setShow }) => (
	<div className="form-check form-switch">
		<input
			className="form-check-input"
			type="checkbox"
			role="switch"
			id="flexSwitchCheckDefault"
			onClick={() => setShow((p) => !p)}
		/>
	</div>
);

export const ContainerWithSwitchHeader = ({
	headerText,
	initialShow = true,
	nobreak,
	children,
}) => {
	const [show, setShow] = useState(initialShow);
	return (
		<div
			className={`rounded shadow d-flex flex-column justify-content-center m-4 rounded border${
				nobreak ? "" : " col-xl-6"
			}`}
		>
			<ContainerHeader>
				{headerText}
				<Switch setShow={setShow} />
			</ContainerHeader>
			{show && children}
		</div>
	);
};

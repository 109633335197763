import React from "react";
import { T } from "./traks";

const NoMatch = () => (
	<div className="m-4">
		<h2>
			<T>Not Found</T>
		</h2>
		<p>
			<T>The page you are looking for cannot be found.</T>
		</p>
	</div>
);

export default NoMatch;

import React, { useContext, useEffect, useState } from "react";
import Login from "./Login";
import APIContext from "../hooks/API";
import { T } from "./traks";

const Authorized = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const { authToken } = useContext(APIContext);
	useEffect(() => {
		setTimeout(() => setLoading(false), 200);
	}, [loading, setLoading]);
	return <>{loading ? <Loading /> : !authToken ? <Login /> : children}</>;
};

const Loading = () => (
	<div>
		<T>Loading</T>
	</div>
);
export default Authorized;

import React, { useState, useEffect, useCallback } from "react";
var baseUrl;

baseUrl =
	process.env.REACT_APP_ENV === "production"
		? "https://api.amopus.com"
		: "https://test-api.amopus.com";

export const InitializeAPI = () => {
	const [error, setError] = useState(null);
	const [notify, setNotify] = useState(null);
	const [loading, setLoading] = useState(false);
	const [companyId, setCompanyId] = useState(false);
	const [authToken, setAuthToken] = useState();

	// Try to fetch locally stored authtoken (only run once)
	useEffect(() => {
		let token = localStorage.getItem("authToken");
		if (token) {
			try {
				token = JSON.parse(token);
				if (token.expires > Date.now()) {
					setAuthToken(token.authToken);
				} else {
					localStorage.clear("authToken");
				}
			} catch (e) {
				localStorage.clear("authToken");
			}
		}
	}, []);

	// Store/remove authToken in local storage on change
	useEffect(() => {
		if (authToken) {
			Date.now();
			let token = { authToken, expires: Date.now() + 1000 * 60 * 60 * 24 };
			localStorage.setItem("authToken", JSON.stringify(token));
		} else {
			localStorage.clear("authToken");
		}
	}, [authToken]);

	const request = useCallback(
		({ method, path = "", args, headers = {} }) => {
			const requestInit = {
				method: method,
				mode: "cors",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: `basic ${authToken}`,
					...headers,
				},
			};
			if (args) {
				requestInit["body"] = JSON.stringify(args);
			}
			setLoading(true);
			return fetch(baseUrl + path, requestInit)
				.then((r) => {
					setLoading(false);
					if (r.ok) {
						// update expires for authToken
						localStorage.setItem(
							"authToken",
							JSON.stringify({
								authToken,
								expires: Date.now() + 1000 * 60 * 60 * 24,
							})
						);
						setError();
						if (
							requestInit.headers.Accept === "application/json" &&
							r.headers.get("Content-Length") > 1
						) {
							return r.json();
						} else {
							return r.blob();
						}
					} else {
						if (r.json) {
							try {
								r.json().then((json) => setError(json.message));
							} catch (e) {
								setAuthToken();
							}
						}

						if (r.status === 401 || r.status === 403) {
							if (path !== "/login" && path !== "/user") {
								setAuthToken();
								console.log("Unauthorized operation.");
							}
						}
					}
				})
				.catch((e) => {
					console.log(e);
					setError(`API error: ${e}`);
				});
		},
		[authToken]
	);

	const post = useCallback(
		({ path = "", args = {} }) => {
			path = companyId ? `${path}?companyId=${companyId}` : path;
			return request({
				method: "POST",
				path: path,
				args: args,
			});
		},
		[request, companyId]
	);

	const put = useCallback(
		({ path = "", args = {} }) => {
			path = companyId ? `${path}?companyId=${companyId}` : path;
			return request({
				method: "PUT",
				path: path,
				args: args,
			});
		},
		[request, companyId]
	);

	const get = useCallback(
		({ path = "", args, headers }) => {
			args = { ...args, ...(companyId ? { companyId } : {}) };
			let query = Object.keys(args).length
				? path + "?" + new URLSearchParams(args)
				: path;
			return request({ method: "GET", path: query, args: null, headers });
		},
		[request, companyId]
	);

	const deleteR = useCallback(
		({ path = "", args, headers }) => {
			args = { ...args, ...(companyId ? { companyId } : {}) };
			let query = args ? path + "?" + new URLSearchParams(args) : path;
			return request({ method: "DELETE", path: query, args: null, headers });
		},
		[request, companyId]
	);

	return {
		post,
		put,
		get,
		deleteR,
		request,
		error,
		notify,
		setNotify,
		authToken,
		setAuthToken,
		loading,
		companyId,
		setCompanyId,
	};
};

const APIContext = React.createContext();

export default APIContext;

import React, { useContext, useEffect, useState } from "react";
import APIContext from "../../hooks/API";
import { T } from "../traks";

const TemplateSelect = ({
	templateKey,
	setTemplateKey,
	showInvoice,
	sendInvoice,
}) => {
	const { get } = useContext(APIContext);
	const [templates, setTemplates] = useState([]);
	useEffect(() => {
		if (!templates.length) {
			get({
				path: "/company/templates",
			}).then((e) => {
				if (e.templates) setTemplates(Object.keys(e.templates));
			});
		}
	}, [get, templates]);
	if (!templates.length) {
		return;
	}
	return (
		<div className="input-group me-2">
			<button
				className="btn btn-primary"
				disabled={!templateKey}
				onClick={showInvoice}
			>
				<T>Show invoice</T>
			</button>
			<button
				className="btn btn-secondary"
				onClick={sendInvoice}
				disabled={!templateKey}
			>
				<T>Send receipt</T>
			</button>
			<select
				className="form-select"
				defaultValue={"DEFAULT"}
				onChange={(e) => {
					setTemplateKey(e.target.value);
				}}
			>
				<option disabled value="DEFAULT">
					<T>Choose template</T>
				</option>
				{templates.map((s) => (
					<option key={s}>{s}</option>
				))}
			</select>
		</div>
	);
};

export default TemplateSelect;

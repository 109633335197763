import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import APIContext, { InitializeAPI } from "./hooks/API";
import reportWebVitals from "./reportWebVitals";

const ContextWrapper = () => {
	const api = InitializeAPI();
	return (
		<div>
			<APIContext.Provider value={api}>
				<App />
			</APIContext.Provider>
		</div>
	);
};

const domNode = document.getElementById("root");
const root = createRoot(domNode);
root.render(<ContextWrapper />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
